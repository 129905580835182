import React from 'react';

import Layout from '../components/Layout';
import SideBar from '../components/Sidebar';

const IndexPage = () => (
	<Layout>
		<SideBar />
		<div id="wrapper">
			<div id="main">
				<section>
					<div className="container">
						<section>
							<h1>Page not found!</h1>
							<p>Please use the below link to start over:</p>
							<a href="/">Home</a>
						</section>
					</div>
				</section>
			</div>
		</div>
	</Layout>
);

export default IndexPage;
